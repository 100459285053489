module.exports = {
  apiKey: 'AIzaSyBa1B0_pjvb6sga8qHfem5e2RgvMp7-i-8',
  authDomain: 'complo.firebaseapp.com',
  databaseURL: 'https://complo.firebaseio.com',
  projectId: 'complo',
  storageBucket: 'complo.appspot.com',
  messagingSenderId: '951863069767',
  appId: '1:951863069767:web:9beb5f73e8739074d63562',
  measurementId: 'G-RNMQK39X31',
}
